import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import MyAccount from '../Pages/MyAccount/MyAccount';
import DashboardSummary from '../Pages/DashboardSummary/DashboardSummary';
import ResetPassword from '../Pages/Password/ResetPassword';
import Contact from '../Pages/Contact/Contact';
import AddContact from '../Pages/Contact/AddContact';
import EditContact from '../Pages/Contact/EditContact';
import CloneContact from '../Pages/Contact/CloneContact';
import ContactEmail from '../Pages/Contact/ContactEmail';
import ContactDetails from '../Pages/Contact/ContactDetails';
import ContactImport from '../Pages/Contact/ContactImport';
import CustomersPayment from '../Pages/CustomersPayment/CustomersPayment';
import AddPaymentsReceived from '../Pages/CustomersPayment/AddPaymentReceived';

import Warehouse from '../Pages/Warehouse/Warehouse';
import AddWarehouse from '../Pages/Warehouse/AddWarehouse';
import EditWarehouse from '../Pages/Warehouse/EditWarehouse';

import Organization from '../Pages/Organization/Organization';
import AddOrganization from '../Pages/Organization/AddOrganization';
import EditOrganization from '../Pages/Organization/EditOrganization';
import ChooseOrganization from '../Pages/Organization/ChooseOrganization';
import RegisterOrganization from '../Auth/Organization/RegisterOrganization';

import Currency from '../Pages/Currency/Currency';
import UpdateCurrency from '../Pages/Currency/UpdateCurrency';
// import AddCurrency from '../Pages/Currency/AddCurrency'
import CreateAccount from '../Pages/Account/CreateAccount';
import AccountList from '../Pages/Account/AccountList';
import AccountGroupList from '../Pages/Account/AccountGroupList';
import ChartOfAccountList from '../Pages/ChartOfAccounts/AccountList';

import ItemsImport from '../Pages/Items/ItemsImport';
import AddItem from '../Pages/Items/AddItem';
import EditItem from '../Pages/Items/EditItem';
import CloneItem from '../Pages/Items/CloneItem';
import ItemList from '../Pages/Items/View/ItemList';
import ItemDetails from '../Pages/Items/ItemDetails';
import ItemAdjustment from '../Pages/Items/Adjustments/ItemsAdjustment';
import AddAdjustments from '../Pages/Items/Adjustments/AddAdjustments';
import ItemAdjustmentDetails from '../Pages/Items/Adjustments/AdjustmentsDetails';
import SalesOrder from '../Pages/SalesOrder/SalesOrder';
import SalesReturn from '../Pages/SalesReturn/SalesReturn';
import CreditNote from '../Pages/CreditNote/CreditNote';
import AddSalesOrder from '../Pages/SalesOrder/AddSalesOrder';
// import AddCreditNote from '../Pages/CreditNote/AddCreditNote'
import CreditNoteDetails from '../Pages/CreditNote/CreditNoteDetails/index';
// import AddSalesReturn from '../Pages/SalesReturn/AddSalesReturn'
import EditSalesReturn from '../Pages/SalesReturn/EditSalesReturn';
import EditCreditNote from '../Pages/CreditNote/EditCreditNote';
import EditSalesOrder from '../Pages/SalesOrder/EditSalesOrder';
import SalesOrderEmail from '../Pages/SalesOrder/SalesOrderEmail';
import CloneSalesOrder from '../Pages/SalesOrder/CloneSalesOrder';
import SalesOrderDetails from '../Pages/SalesOrder/SalesOrderDetails';
import NewPackageCreate from '../Pages/SalesOrder/Packages/AddPackage';
import NewPackageEdit from '../Pages/SalesOrder/Packages/EditPackage';
import ShipmentCreate from '../Pages/SalesOrder/Packages/ShipmentCreate';
import PackageList from '../Pages/SalesOrder/Packages/PackageList';
import PackageDetails from '../Pages/SalesOrder/Packages/PackageDetails';

import SaleReturnDetails from '../Pages/SalesReturn/SalesReturnDetails';
import AddInvoice from '../Pages/SalesOrder/Invoices/AddInvoice';
import InvoiceDetails from '../Pages/SalesOrder/Invoices/InvoiceDetails';
import EditInvoice from '../Pages/SalesOrder/Invoices/EditInvoice';
import InvoiceMail from '../Pages/SalesOrder/Invoices/InvoiceEmail';
import PackageMail from '../Pages/SalesOrder/Packages/PackageEmail';
import ShipmentMail from '../Pages/SalesOrder/Packages/ShipmentEmail';
import InvoiceList from '../Pages/SalesOrder/Invoices/InvoiceList';
import InvoicePaymentDetails from '../Pages/SalesOrder/Invoices/InvoiceDetails/Tabs/Payments/InvoicePaymentDetails';
import PurchaseOrder from '../Pages/PurchaseOrder/View/PurchaseOrder';
import AddPurchaseOrder from '../Pages/PurchaseOrder/AddPurchaseOrder';
import EditPurchaseOrder from '../Pages/PurchaseOrder/EditPurchaseOrder';
import PurchaseOrderEmail from '../Pages/PurchaseOrder/PurchaseOrderEmail';
import ClonePurchaseOrder from '../Pages/PurchaseOrder/ClonePurchaseOrder';
import PurchaseOrderDetails from '../Pages/PurchaseOrder/PurchaseOrderDetails';
import BillDetails from '../Pages/PurchaseOrder/Bills/BillDetails';
import BillsPage from '../Pages/PurchaseOrder/Bills/BillsPage/BillsPage';
import EditBill from '../Pages/PurchaseOrder/Bills/Edit/EditBill';
import AddBill from '../Pages/PurchaseOrder/Bills/Add/AddBill';
import BillPaymentDetails from '../Pages/PurchaseOrder/Bills/BillDetails/Tabs/Payments/BillPaymentDetails';
import AddDropship from '../Pages/Dropship/AddDropship';
import AddBackorder from '../Pages/Backorder/AddBackorder';
import Preference from '../Pages/Preference/Preference';
import NumberPreferences from '../Pages/NumberPreferences/NumberPreferences';

import NotFoundPages from '../Pages/NotFoundPages/NotFoundPages';
import AddTax from '../Pages/Tax/AddTax';
import EditTax from '../Pages/Tax/EditTax';
import TaxList from '../Pages/Tax/TaxList';
import DeliveryMethodList from '../Pages/DeliveryMethod/DeliveryMethodList';
import AddDeliveryMethod from '../Pages/DeliveryMethod/AddDeliveryMethod';
import EditDeliveryMethod from '../Pages/DeliveryMethod/EditDeliveryMethod';

import AlertDataUpdate from '../Components/Alert/AlertDataUpdate';
import PaymentsDetails from '../Pages/CustomersPayment/PaymentsDetails';

import Settings from '../Pages/Settings/Settings';
import PackagesPlan from '../Pages/PackagesPlan/PackagesPlan';

import Navigation from '../Pages/Navigation/Navigation';
import Header from '../Components/Header/Header';
import InitialLoader from '../Components/Loader/InitialLoader';
import Hes from '../Helpers/Test';
import AuthenticatedRoute from '../Helpers/AuthenticatedRoute';
import { restRequest } from '../Helpers/RequestHelper';
import {
  getObjFromSS,
  removeObjFromSS,
  setObjInLS,
  setObjInSS,
} from '../Helpers/LocalStorage';
import {
  validToken,
  logout,
  setOrganization,
  getOrganization,
  checkError,
  setDeploymentTime,
} from '../Helpers/AuthHelper';
import { UserContext } from '../Context/UserContext';
//import Logout from '../Auth/Logout';

import './ProtectedRoutes.css';

// echo, socket
import Echo from 'laravel-echo';
import Loader from '../Components/Loader/Loader';
import PaymentMail from '../Pages/SalesOrder/Invoices/InvoiceDetails/Tabs/Payments/PaymentsEmail';
import BillPaymentEmail from '../Pages/PurchaseOrder/Bills/BillDetails/Tabs/Payments/BillPaymentsEmail';
import {
  SyncItemDetails,
  SyncSaleOrdersDetails,
  SyncStockDetails,
} from '../Pages/Integrations/Ecom/SyncDetails';
import { UnSyncOrderDetail, UnsyncOrders } from '../Components';
// import {routesPath} from "../Pages/Navigation/NavigationUtils";
import SalesReturnEmail from '../Pages/SalesReturn/SalesReturnEmail';
import ReturnReceiveEmail from '../Pages/SalesReturn/SalesReturnDetails/Info/ReturnReceiveEmail';
import CreditNoteEmail from '../Pages/CreditNote/CreditNoteEmail';
import PaymentModeList from '../Pages/PaymentMode/PaymentModeList';
import ContactStatementEmail from '../Pages/Contact/ContactDetails/Tabs/Statement/ContactStatementEmail';
import ItemGroups from '../Pages/Items/ItemGroups/ItemGroups';
import AddItemGroups from '../Pages/Items/ItemGroups/AddItemGroups';
// import ItemGroupEnhancerStepTwo from '../Components/Form/ItemForm/ItemGroupEnhancerStepTwo'
import EditItemGroups from '../Pages/Items/ItemGroups/EditItemGroups';
import AddItemExistingGroup from '../Pages/Items/ItemGroups/AddItemExistingGroup';
import ItemGroupDetails from '../Pages/Items/ItemGroups/ItemGroupDetails';
import AddCustomerPayment from '../Pages/Contact/CustomerPayments/AddCustomerPayment';
import EditPaymentsReceived from '../Pages/CustomersPayment/EditPaymentReceived';
import CustomerPaymentEmail from '../Pages/CustomersPayment/CustomerPaymentsEmail';
import MobilePrint from '../Common/MobilePrint';
import ReportsPrint from '../Common/MobilePrint/ReportsPrint';
import UnSycnItemsMapping from '../Components/UnsyncOrders/Mapping';
import Cookies from 'universal-cookie';
import { MapOldAccount } from '../Pages/ChartOfAccounts';
const noInternet = require('../Assets/Img/no-internet.png');

// import noInternet from "../Assets/Img/org-image.jpg";
window.io = require('socket.io-client');

const Reports = lazy(() => import('../Pages/Reports'));

const MyApp = lazy(() =>
  import(/* webpackChunkName: 'My_apps' */ '../Pages/Integrations/MyApps')
);
const StoreDetail = lazy(() =>
  import(
    /* webpackChunkName: 'Store_details' */ '../Pages/Integrations/Ecom/Details'
  )
);
const ShopifyDetails = lazy(() =>
  import(
    /* webpackChunkName: 'Store_details' */ '../Pages/Integrations/Ecom/Shopify/ShopifyDetails'
  )
);
const Integrations = lazy(() =>
  import(/* webpackChunkName: 'Integrations' */ '../Pages/Integrations')
);

const SyncHistory = lazy(() =>
  import(
    /* webpackChunkName: 'Sync_history' */ '../Pages/Integrations/Ecom/SyncHistory'
  )
);
const InventoryDetailsReport = lazy(() =>
  import('../Pages/Reports/Report/InventoryDetailsReport')
);
const InventoryValuationSummary = lazy(() =>
  import('../Pages/Reports/Report/InventoryValuationSummary')
);
const FifoCostTrackingReport = lazy(() =>
  import('../Pages/Reports/Report/FifoCostTrackingReport')
);
const WarehouseReport = lazy(() =>
  import('../Pages/Reports/Report/WarehouseReport')
);
const CommittedstockReport = lazy(() =>
  import('../Pages/Reports/Report/CommittedstockReport')
);
const ProductsalesReport = lazy(() =>
  import('../Pages/Reports/Report/ProductsalesReport')
);
const ActivepurchaseordersReport = lazy(() =>
  import('../Pages/Reports/Report/ActivepurchaseordersReport')
);
const SalesOrderReport = lazy(() =>
  import('../Pages/Reports/Report/SalesOrderReport')
);
const InvoiceReport = lazy(() =>
  import('../Pages/Reports/Report/InvoiceReport')
);
const PaymentsReceivedReport = lazy(() =>
  import('../Pages/Reports/Report/PaymentsReceivedReport')
);
const RefundReport = lazy(() => import('../Pages/Reports/Report/RefundReport'));
const SalesReturnReport = lazy(() =>
  import('../Pages/Reports/Report/SalesReturnReport')
);
const CustomerReport = lazy(() =>
  import('../Pages/Reports/Report/CustomerReport')
);
const SalesbyitemReport = lazy(() =>
  import('../Pages/Reports/Report/SalesbyitemReport')
);
const SalesbysalespersonReport = lazy(() =>
  import('../Pages/Reports/Report/SalesbysalespersonReport')
);
const OrderfulfillmentbyitemReport = lazy(() =>
  import('../Pages/Reports/Report/OrderfulfillmentbyitemReport')
);
const PurchaseOrderReport = lazy(() =>
  import('../Pages/Reports/Report/PurchaseOrderReport')
);
const ReceiveReport = lazy(() =>
  import('../Pages/Reports/Report/ReceiveReport')
);
const BillReport = lazy(() => import('../Pages/Reports/Report/BillReport'));
const PaymentsMadeReport = lazy(() =>
  import('../Pages/Reports/Report/PaymentsMadeReport')
);
const PackingReport = lazy(() =>
  import('../Pages/Reports/Report/PackingReport')
);
const ItemMapping = lazy(() =>
  import(
    /* webpackChunkName: 'Items_mapping' */ '../Pages/Integrations/Ecom/ItemMapping'
  )
);
const ShopifyItemMapping = lazy(() =>
  import(
    /* webpackChunkName: 'Items_mapping' */ '../Pages/Integrations/Ecom/Shopify/ShopifyItemMapping'
  )
);
const TaxesReport = lazy(() => import('../Pages/Reports/Report/TaxesReport'));
const cookies = new Cookies();

class ProtectedRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      currentOrganization: {},
      viewNavigation:
        JSON.parse(localStorage.getItem('viewNavigation')) || false,
      notRegisteredOrganization: false,
      mappingAccount: false,
      initialLoading: true,
      updateData: false,
      role: null,
      deploymentTime: false,
    };
  }
  componentDidMount() {
    localStorage.setItem('alert', JSON.stringify(false));
    this.initiateEventListeners();
    this.checkResetPasswordLink();
    this.initialLoading();
    window.addEventListener('storage', this.listener);
    window.addEventListener('focus', this.onFocus);
  }

  listener() {
    let vl = JSON.parse(localStorage.getItem('switch_org'));

    if (vl) {
      setObjInSS('switch_org', JSON.stringify(vl));
      localStorage.setItem('switch_org', JSON.stringify(false));
      setObjInSS('checking', true);
    }
  }

  onFocus = () => {
    let val = getObjFromSS('checking');
    let stat = JSON.parse(getObjFromSS('switch_org'));

    if (val && stat) {
      window.location.reload();
      val && removeObjFromSS('checking');
      val && removeObjFromSS('switch_org');
    }
  };

  async initialLoading() {
    const user_status = cookies.get('user_status');
    const account_status = cookies.get('account_status');
    //console.log('valid token', validToken())
    //console.log('valid token ----------------')
    if (user_status !== 'in_active' && validToken()) {
      try {
        await this.fetchOrganization();
        await this.fetchDeployment();
        await this.fetchPermissions();
        this.startEchoServer();
        this.setState({ initialLoading: false });
      } catch {
        // logout()
        this.props.history.push('/');
      }
    } else {
      if (!validToken()) {
        console.log('--------------------- here in protected routes');
        this.props.history.push('/');
      } else {
        cookies.remove('account_status', {
          path: '/',
          domain: `${process.env.REACT_APP_COOKIEPARENT}`,
        });
        switch (account_status) {
          case 'inactive_user':
            console.log('-------------inactive_user ', account_status);
            this.props.history.push('/userDeactivate');
            return;
          case 'inactive_account':
            console.log('-------------inactive_account ', account_status);
            this.props.history.push('/accountDeactivate');
            return;
          default:
            console.log('-------------default ', account_status);
            this.props.history.push('/serverAuthenticate');
            return;
        }
        //account_status === 'inactive_user' ? this.props.history.push('/userDeactivate') : this.props.history.push('/accountDeactivate');
      }
      this.setState({ initialLoading: false });
    }
  }
  async fetchDeployment() {
    caches.keys().then((cach) => console.log({ cach }));
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    await restRequest('get', `deploymentTime`)
      .then((res) => {
        setDeploymentTime(res);
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  initiateEventListeners() {
    window.addEventListener('storage', this.onLogout);
  }
  onLogout = (event) => {
    if (event.key === 'logout-event') {
      logout();
      this.props.history.push('/login');
    }
  };
  fetchOrganization() {
    // post token, check force update
    return restRequest('get', 'auth/user')
      .then((currentUser) => {
        cookies.remove('logout_token', {
          path: '/',
          domain: `${process.env.REACT_APP_COOKIEPARENT}`,
        });
        // localStorage.setItem('currentOrganization', JSON.stringify(currentUser))
        this.setState({ currentUser });
        if (!currentUser.current_organization_id) {
          this.setState({ notRegisteredOrganization: true });
          this.props.history.replace('/');
        } else {
          const organizationId = currentUser.current_organization_id;
          setOrganization(organizationId);
          restRequest('get', `organizations/${organizationId}`, {})
            .then((organizationResponse) => {
              if (organizationResponse) {
                this.setState({
                  currentOrganization: organizationResponse,
                });
                localStorage.setItem(
                  'currentOrganization',
                  JSON.stringify(organizationResponse)
                );
              }
              this.fetchMapOldAccounts();
            })
            .catch((error) => {
              if (error?.response?.data?.message === 'Access Denied') {
                this.props.history.push('/organization/choose');
              }
            });
        }
      })
      .catch((er) => {
        logout();
        this.props.history.replace('/');
      });
  }
  async fetchMapOldAccounts() {
    await restRequest('get', `old_accounts`)
      .then((res) => {
        console.log({ s: res?.data?.status });
        if (res?.data?.status) {
          this.setState({ mappingAccount: true });
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  fetchPermissions = (checkToken = true, showToast = false) => {
    if ((checkToken && !validToken()) || this.state.notRegisteredOrganization)
      return;
    if (showToast)
      this.props.handleToast(
        'A change has been made by Admin! Refreshing....',
        'success'
      );
    return restRequest('get', 'currentuser/role').then((res) => {
      this.setState({ role: res });
      setObjInLS('role', res);
    });
  };

  startEchoServer() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return;
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: process.env.REACT_APP_ECHO_SERVER,
    });
    window.Echo.channel('roleupdatechannel')
      .listen('RoleUpdateEvent', (e) => {
        if (validToken() && getOrganization()) {
          const role = this.state.role;
          if (role.superAccess) return;
          if (role && role.id === e.role.id) {
            this.fetchPermissions(false, true);
          }
        }
      })
      .listen('RoleSwitchEvent', (e) => {
        if (validToken() && getOrganization()) {
          const role = this.state.role;
          if (role.superAccess) return;
          if (
            role &&
            this.state.currentUser.id === e.userId &&
            role.id === e.previousRoleId &&
            e.previousRoleId !== e.role.id
          ) {
            this.fetchPermissions(false, true);
          }
        }
      });

    window.Echo.channel('deleteuserchannel').listen('DeleteUserEvent', (e) => {
      if (validToken() && getOrganization()) {
        if (
          e.userId === this.state.currentUser.id &&
          getOrganization() === e.orgId &&
          e.isDelete === false &&
          e.isActive === 0
        ) {
          this.props.handleToast(
            'Your account has been blocked by Admin for this business and the system is logging you out.' +
              ' Please contact Admin for further process.',
            'error'
          );
          setTimeout(() => {
            logout();
            this.props.history.replace('/');
          }, 2000);
        } else {
          restRequest('get', 'auth/user').then((currentUser) => {
            if (
              e.userId === currentUser.id &&
              getOrganization() === e.orgId &&
              e.isDelete === true
            ) {
              this.props.handleToast(
                'Your account has been removed by Admin from this business. Logging out your session...',
                'error'
              );
              setTimeout(() => {
                logout();
                this.props.history.replace('/');
              }, 2000);
            }
          });
        }
      }
    });

    window.Echo.channel('moduledataupdatechannel').listen(
      'ModuleDataUpdateEvent',
      (e) => {
        if (validToken() && getOrganization()) {
          // show toaster here for reload your page, data updated
          this.setState({ updateData: true });
        }
      }
    );
  }

  componentWillUnmount() {
    this.removeEventListeners();
    this.leaveEchoChannel();
  }

  removeEventListeners() {
    window.removeEventListener('storage', this.onLogout);
    window.removeEventListener('offline', () => null);
    window.removeEventListener('online', () => null);
  }

  leaveEchoChannel() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return;
    if (window.Echo) {
      window.Echo.leave('roleupdatechannel');
      window.Echo.leave('deleteuserchannel');
    }
  }

  changeRole = (role) => {
    this.setState({ role });
  };

  // can be used later to replace whole user object
  changeUserName = (name) => {
    this.setState({
      currentUser: { ...this.state.currentUser, name: name },
    });
  };

  toggleNavigation = () => {
    localStorage.setItem('viewNavigation', !this.state.viewNavigation);
    this.setState({ viewNavigation: !this.state.viewNavigation });
  };
  toggleNavigationHeader = () => {
    localStorage.setItem('viewNavigation', false);
    this.setState({ viewNavigation: false });
  };

  // check reset password
  checkResetPasswordLink = () => {
    if (window.location.search.includes('?token=')) {
      this.props.history.push('/dashboard');
    }
  };
  render() {
    const ssoConnected =
      process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
    const { handleToast, history } = this.props;
    if (this.state.initialLoading) return <InitialLoader />;
    return (
      <UserContext.Provider
        value={{
          role: this.state.role,
          currentUser: this.state.currentUser,
          changeRole: this.changeRole,
          changeUserName: this.changeUserName,
        }}
      >
        {this.state.notRegisteredOrganization ? (
          <RegisterOrganization
            handleToast={handleToast}
            fetchPermissions={this.fetchPermissions}
            history={history}
            onSubmit={(callBack) => {
              this.setState({ notRegisteredOrganization: false }, () =>
                callBack ? callBack() : null
              );
            }}
          />
        ) : (
          <div className="main">
            <Header
              viewNavigation={this.state.viewNavigation}
              toggleNavigation={this.toggleNavigation}
              currentUser={this.state.currentUser}
              toggleNavigationHeader={this.toggleNavigationHeader}
              ssoConnected={ssoConnected}
            />
            <div className="app-main">
              <Navigation
                viewNavigation={this.state.viewNavigation}
                toggleNavigation={this.toggleNavigation}
                ssoConnected={ssoConnected}
              />
              <div className="app-content scrollbar_style ">
                <img
                  src={noInternet}
                  style={{ display: 'none' }}
                  alt="No Internet connectivity."
                />
                {this.state.offline ? (
                  <div className="no_internet">
                    <img src={noInternet} alt="No Internet connectivity." />
                    <p>
                      Seems like you don`t have an active internet connection.
                    </p>
                    <p>
                      Please check your internet and{' '}
                      <span
                        onClick={() => window.location.reload()}
                        className="blue_text"
                      >
                        Try Again.
                      </span>
                    </p>
                  </div>
                ) : this.state.mappingAccount ? (
                  <MapOldAccount
                    history={history}
                    handleToast={handleToast}
                    onSubmit={() => this.setState({ mappingAccount: false })}
                  />
                ) : (
                  <>
                    <Suspense fallback={<Loader />}>
                      <Switch>
                        {/*DASHBOARD*/}
                        <AuthenticatedRoute
                          exact
                          path="/"
                          component={DashboardSummary}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/dashboard"
                          component={DashboardSummary}
                          handleToast={handleToast}
                        />
                        <Redirect exact from="/login" to="/" />
                        {/*RESET PASSWORD*/}
                        <AuthenticatedRoute
                          exact
                          path="/password/reset"
                          component={ResetPassword}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/r"
                          component={Hes}
                          handleToast={handleToast}
                        />
                        <Route
                          path="/signup"
                          render={(props) => (
                            <>{this.props.history.push('/')}</>
                          )}
                        />
                        {/* Package Plans */}
                        <AuthenticatedRoute
                          exact
                          path="/packagesplan"
                          component={PackagesPlan}
                          handleToast={handleToast}
                        />
                        {/*ORGANIZATION*/}
                        <AuthenticatedRoute
                          exact
                          path="/useraccess"
                          component={Organization}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/organization/add"
                          component={AddOrganization}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/oc/organization/choose"
                          component={ChooseOrganization}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="OrganizationEdit"
                          exact
                          path="/organization/edit"
                          component={EditOrganization}
                          handleToast={handleToast}
                        />
                        {/*TAX*/}
                        <AuthenticatedRoute
                          checkPermission="TaxView"
                          exact
                          path="/taxes"
                          component={TaxList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="TaxCreate"
                          exact
                          path="/taxes/add"
                          component={AddTax}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="TaxEdit"
                          exact
                          path="/taxes/edit/:id"
                          component={EditTax}
                          handleToast={handleToast}
                        />
                        {/*Delivery Method*/}
                        <AuthenticatedRoute
                          checkPermission="DeliveryMethodView"
                          exact
                          path="/deliverymethods"
                          component={DeliveryMethodList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="DeliveryMethodCreate"
                          exact
                          path="/deliverymethods/add"
                          component={AddDeliveryMethod}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="DeliveryMethodEdit"
                          exact
                          path="/deliverymethods/edit/:id"
                          component={EditDeliveryMethod}
                          handleToast={handleToast}
                        />
                        {/*WAREHOUSE*/}
                        <AuthenticatedRoute
                          checkPermission="WarehouseView"
                          exact
                          path="/warehouses"
                          component={Warehouse}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="WarehouseCreate"
                          exact
                          path="/warehouses/add"
                          component={AddWarehouse}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="WarehouseEdit"
                          exact
                          path="/warehouses/edit/:id"
                          component={EditWarehouse}
                          handleToast={handleToast}
                        />
                        {/*PAYMENT MODES*/}
                        <AuthenticatedRoute
                          checkPermission="PaymentModeView"
                          exact
                          path="/paymentmethods"
                          component={PaymentModeList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PaymentModeCreate"
                          exact
                          path="/paymentmethods/add"
                          component={AddWarehouse}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PaymentModeEdit"
                          exact
                          path="/paymentmethods/edit/:id"
                          component={EditWarehouse}
                          handleToast={handleToast}
                        />
                        {/*Integration route*/}
                        <AuthenticatedRoute
                          checkPermission="Integrations"
                          path="/integrations"
                          component={Integrations}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/store/details/:id"
                          component={StoreDetail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/shopify/details/:id"
                          component={ShopifyDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/sync-history/:id"
                          component={SyncHistory}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/my-apps"
                          component={MyApp}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/item-mapping/:id"
                          component={ItemMapping}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/shopify/item-mapping/:id"
                          component={ShopifyItemMapping}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/sync-item-detail/:id"
                          component={SyncItemDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/sync-salesorder-detail/:id"
                          component={SyncSaleOrdersDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/integrations/sync-stock-detail/:id"
                          component={SyncStockDetails}
                          handleToast={handleToast}
                        />
                        {/*USER*/}
                        <AuthenticatedRoute
                          path="/myaccount"
                          component={MyAccount}
                          handleToast={handleToast}
                        />
                        {/* /myaccount?changePassword=true&q=MTYw */}
                        {/*CONTACT*/}
                        <AuthenticatedRoute
                          checkPermission="ContactView"
                          exact
                          path="/contacts"
                          component={Contact}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ContactCreate"
                          exact
                          path="/contacts/add"
                          component={AddContact}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ContactEdit"
                          exact
                          path="/contacts/edit/:id"
                          component={EditContact}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ContactCreate"
                          exact
                          path="/contacts/clone/:id"
                          component={CloneContact}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/contacts/email/:id"
                          component={ContactEmail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/contacts/statement/:id/:starting_date/:ending_date"
                          component={ContactStatementEmail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/contacts/import"
                          component={ContactImport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/contacts/:id"
                          component={ContactDetails}
                          handleToast={handleToast}
                        />
                        {/* Customers Payment */}
                        <AuthenticatedRoute
                          checkPermission="InvoicePaymentRecordView"
                          exact
                          path="/paymentsmade"
                          component={CustomersPayment}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InvoicePaymentRecordCreate"
                          exact
                          path="/paymentsmade/add"
                          component={AddPaymentsReceived}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InvoicePaymentRecordEdit"
                          exact
                          path="/paymentsmade/edit/:id"
                          component={EditPaymentsReceived}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/paymentsmade/:id"
                          component={PaymentsDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/paymentsmade/email/:id"
                          component={CustomerPaymentEmail}
                          handleToast={handleToast}
                        />
                        {/* Customers Payment */}
                        {/*CURRENCY*/}
                        <AuthenticatedRoute
                          checkPermission="CurrencyView"
                          exact
                          path="/currencies"
                          component={Currency}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="CurrencyEdit"
                          exact
                          path="/currencies/update/:id"
                          component={UpdateCurrency}
                          handleToast={handleToast}
                        />
                        {/* <AuthenticatedRoute checkPermission="CurrencyCreate" exact path='/currencies/add' component={AddCurrency} handleToast={handleToast} /> */}
                        {/* ACCOUNT*/}
                        <AuthenticatedRoute
                          checkPermission="AccountCreate"
                          exact
                          path="/accounting/account/new"
                          component={CreateAccount}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="AccountView"
                          exact
                          path="/accounting/account"
                          component={AccountList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="AccountView"
                          exact
                          path="/setting/account"
                          component={ChartOfAccountList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="AccountView"
                          exact
                          path="/accounting/accountgroup"
                          component={AccountGroupList}
                          handleToast={handleToast}
                        />
                        {/*ITEMS*/}
                        <AuthenticatedRoute
                          checkPermission="ItemView"
                          exact
                          path="/items"
                          component={ItemList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/items/import"
                          component={ItemsImport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/items"
                          component={ItemList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemCreate"
                          exact
                          path="/items/add"
                          component={AddItem}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemEdit"
                          exact
                          path="/items/edit/:id"
                          component={EditItem}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemCreate"
                          exact
                          path="/items/clone/:id"
                          component={CloneItem}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemAdjustmentView"
                          exact
                          path="/item-adjustments"
                          component={ItemAdjustment}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemAdjustmentCreate"
                          exact
                          path="/item-adjustments/add"
                          component={AddAdjustments}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/items/:id"
                          component={ItemDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/item-adjustments/:id"
                          component={ItemAdjustmentDetails}
                          handleToast={handleToast}
                        />
                        {/* Item Groups in Progress*/}
                        <AuthenticatedRoute
                          checkPermission="ItemGroupView"
                          exact
                          path="/item-groups"
                          component={ItemGroups}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemGroupCreate"
                          exact
                          path="/item-groups/add"
                          component={AddItemGroups}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/item-groups/:id"
                          component={ItemGroupDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemGroupEdit"
                          exact
                          path="/item-groups/edit/:id"
                          component={EditItemGroups}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="ItemGroupCreate"
                          exact
                          path="/item-groups/addItem/:id"
                          component={AddItemExistingGroup}
                          handleToast={handleToast}
                        />
                        {/* Sales Return */}
                        <AuthenticatedRoute
                          checkPermission="SalesReturnView"
                          exact
                          path="/salesreturns"
                          component={SalesReturn}
                          handleToast={handleToast}
                        />
                        {/* below route has been disabled at the moment, will be anc can be used for routing to creating form for sales return  */}
                        {/* <AuthenticatedRoute checkPermission="SalesReturnCreate" exact path='/salesreturns/add' component={AddSalesReturn} handleToast={handleToast} /> */}
                        <AuthenticatedRoute
                          checkPermission="SalesReturnCreate"
                          exact
                          path="/salesreturns/add"
                          component={NotFoundPages}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesReturnEdit"
                          exact
                          path="/salesreturns/edit/:id"
                          component={EditSalesReturn}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/salesreturns/:id"
                          component={SaleReturnDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/salesreturns/email/:id"
                          component={SalesReturnEmail}
                          handleToast={handleToast}
                        />
                        {/* Return Receive */}
                        <AuthenticatedRoute
                          exact
                          path="/returnreceives/email/:id"
                          component={ReturnReceiveEmail}
                          handleToast={handleToast}
                        />
                        {/* Credit Note*/}
                        <AuthenticatedRoute
                          checkPermission="CreditNoteView"
                          exact
                          path="/creditnotes"
                          component={CreditNote}
                          handleToast={handleToast}
                        />
                        {/* below route has been disabled at the moment, will be anc can be used for routing to creating form for creditnotes  */}
                        {/* <AuthenticatedRoute checkPermission="CreditNoteCreate" exact path='/creditnotes/add' component={AddCreditNote} handleToast={handleToast} /> */}
                        <AuthenticatedRoute
                          checkPermission="CreditNoteCreate"
                          exact
                          path="/creditnotes/add"
                          component={NotFoundPages}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="CreditNoteEdit"
                          exact
                          path="/creditnotes/edit/:id"
                          component={EditCreditNote}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/creditnotes/:id"
                          component={CreditNoteDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/creditnotes/email/:id"
                          component={CreditNoteEmail}
                          handleToast={handleToast}
                        />
                        {/*SALES ORDER*/}
                        <AuthenticatedRoute
                          checkPermission="SalesOrderView"
                          exact
                          path="/salesorders"
                          component={SalesOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderCreate"
                          exact
                          path="/salesorders/add"
                          component={AddSalesOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderEdit"
                          exact
                          path="/salesorders/edit/:id"
                          component={EditSalesOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/salesorders/email/:id"
                          component={SalesOrderEmail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderCreate"
                          exact
                          path="/salesorders/clone/:id"
                          component={CloneSalesOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/salesorders/:id"
                          component={SalesOrderDetails}
                          handleToast={handleToast}
                        />
                        {/*PURCHASE ORDER*/}
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderView"
                          exact
                          path="/purchaseorders"
                          component={PurchaseOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderCreate"
                          exact
                          path="/purchaseorders/add"
                          component={AddPurchaseOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderEdit"
                          exact
                          path="/purchaseorders/edit/:id"
                          component={EditPurchaseOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderCreate"
                          exact
                          path="/purchaseorders/clone/:id"
                          component={ClonePurchaseOrder}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/purchaseorders/email/:id"
                          component={PurchaseOrderEmail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/purchaseorders/:id"
                          component={PurchaseOrderDetails}
                          handleToast={handleToast}
                        />
                        {/*DROPSHIP*/}
                        <AuthenticatedRoute
                          exact
                          path="/dropship/add"
                          component={AddDropship}
                          handleToast={handleToast}
                        />
                        {/*BACKORDER*/}
                        <AuthenticatedRoute
                          exact
                          path="/backorder/add"
                          component={AddBackorder}
                          handleToast={handleToast}
                        />
                        {/*PACKAGE*/}
                        <AuthenticatedRoute
                          checkPermission="PackageView"
                          exact
                          path="/packages"
                          component={PackageList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PackageCreate"
                          exact
                          path="/packages/add"
                          component={NewPackageCreate}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PackageEdit"
                          exact
                          path="/packages/edit/:id"
                          component={NewPackageEdit}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/packages/:id"
                          component={PackageDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/shipment/add"
                          component={ShipmentCreate}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/packages/email/:id"
                          component={PackageMail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/shipment/email/:id"
                          component={ShipmentMail}
                          handleToast={handleToast}
                        />
                        {/*INVOICE*/}
                        <AuthenticatedRoute
                          checkPermission="InvoiceView"
                          exact
                          path="/invoices"
                          component={InvoiceList}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InvoiceCreate"
                          exact
                          path="/invoices/add"
                          component={AddInvoice}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/invoices/:id"
                          component={InvoiceDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/invoices/:id/payments/:id"
                          component={InvoicePaymentDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InvoiceEdit"
                          exact
                          path="/invoices/edit/:id"
                          component={EditInvoice}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/invoices/email/:id"
                          component={InvoiceMail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/invoice_payments/email/:id"
                          component={PaymentMail}
                          handleToast={handleToast}
                        />
                        {/*BILL*/}
                        <AuthenticatedRoute
                          checkPermission="BillView"
                          exact
                          path="/bills"
                          component={BillsPage}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="BillCreate"
                          exact
                          path="/bills/add"
                          component={AddBill}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/bills/:id"
                          component={BillDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/bills/:id/payments/:id"
                          component={BillPaymentDetails}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="BillEdit"
                          exact
                          path="/bills/edit/:id"
                          component={EditBill}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/bill_payments/email/:id"
                          component={BillPaymentEmail}
                          handleToast={handleToast}
                        />
                        {/*PREFERENCE*/}
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/preference"
                          component={Preference}
                          handleToast={handleToast}
                        />
                        {/*Number PREFERENCE*/}
                        <AuthenticatedRoute
                          checkPermission="PreferenceView"
                          path="/numberPreferences"
                          component={NumberPreferences}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/settings"
                          component={Settings}
                          handleToast={handleToast}
                        />
                        {/*REPORTS */}
                        <AuthenticatedRoute
                          exact
                          path="/reports"
                          component={Reports}
                          handleToast={handleToast}
                        />
                        warehouses
                        {/* INVENTORY REPORTS */}
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/inventorydetails"
                          component={InventoryDetailsReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/inventoryvaluation"
                          component={InventoryValuationSummary}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/fifo"
                          component={FifoCostTrackingReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/warehouse"
                          component={WarehouseReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/committedstock"
                          component={CommittedstockReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/productsales"
                          component={ProductsalesReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="InventoryReportView"
                          exact
                          path="/reports/activepurchaseorders"
                          component={ActivepurchaseordersReport}
                          handleToast={handleToast}
                        />
                        {/*/!* SALES REPORTS *!/*/}
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/salesorder"
                          component={SalesOrderReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/un-sync-orders"
                          component={UnsyncOrders}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/unsyncorder/:id/:store"
                          component={UnSyncOrderDetail}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/invoice"
                          component={InvoiceReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/paymentsreceived"
                          component={PaymentsReceivedReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/refunds_report"
                          component={RefundReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/packing"
                          component={PackingReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/salesreturn"
                          component={SalesReturnReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/customer"
                          component={CustomerReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/salesbyitem"
                          component={SalesbyitemReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/salesbysalesperson"
                          component={SalesbysalespersonReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="SalesOrderReportView"
                          exact
                          path="/reports/orderfulfillmentbyitem"
                          component={OrderfulfillmentbyitemReport}
                          handleToast={handleToast}
                        />
                        {/*/!* PURCHASES REPORTS *!/*/}
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderReportView"
                          exact
                          path="/reports/purchaseorder"
                          component={PurchaseOrderReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderReportView"
                          exact
                          path="/reports/receive"
                          component={ReceiveReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderReportView"
                          exact
                          path="/reports/bill"
                          component={BillReport}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          checkPermission="PurchaseOrderReportView"
                          exact
                          path="/reports/paymentsmade"
                          component={PaymentsMadeReport}
                          handleToast={handleToast}
                        />
                        {/*/!* TAXES REPORTS *!/*/}
                        <AuthenticatedRoute
                          checkPermission="TaxReportView"
                          exact
                          path="/reports/taxreport"
                          component={TaxesReport}
                          handleToast={handleToast}
                        />
                        {/* Customer Payment */}
                        <AuthenticatedRoute
                          checkPermission="SalesOrderCreate"
                          exact
                          path="/customer_payments/add"
                          component={AddCustomerPayment}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/print/:id/:from"
                          component={MobilePrint}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/print/:from"
                          component={ReportsPrint}
                          handleToast={handleToast}
                        />
                        <AuthenticatedRoute
                          exact
                          path="/unsync-item-mapping/:unSyncOrderId/:storeId/:slug"
                          component={UnSycnItemsMapping}
                          handleToast={handleToast}
                        />
                        {/* <AuthenticatedRoute
                          exact
                          path="/mappingaccount"
                          component={MapOldAccount}
                          handleToast={handleToast}
                        /> */}
                        {/*404 NOT FOUND*/}
                        <Route component={NotFoundPages} />
                      </Switch>
                    </Suspense>
                    {this.state.updateData && (
                      <AlertDataUpdate title="Search Criteria"></AlertDataUpdate>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </UserContext.Provider>
    );
  }
}

export default withRouter(ProtectedRoutes);
