import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import {
  NewSalesOrderIcon,
  SalesOrderIcon,
} from '../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../Helpers/RequestHelper';
import GenerateOptions from '../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../Navigation/NavigationUtils';
import shouldShowModal from '../../Helpers/ShouldShowModal';
import getStatusColor from '../../Helpers/GetStatusColor';
import SalesOrderFlowDiagram from '../../Components/Onboarding/Diagrams/SalesOrderFlowDiagram';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../Helpers/LocalStorage';

import { HorizontalIconColumnJoin } from '../../Components/ListTable/ListTableColumnJoin';
import Alert from '../../Components/Alert/Alert';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import PDFViewer from '../../Components/PDFViewer/PDFViewer';
import BulkDropshipBackorder from './BackorderDropship/BulkDropshipBackorder';
import CustomModal from '../../Components/CustomModal/CustomModal';
import ListTable from '../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../Components/SearchBar/SearchResult';
import Export from '../../Components/Export/Export';
import Onboarding from '../../Components/Onboarding/InitialOnboardingScreen';
import SalesOrderDetails from '../../Pages/SalesOrder/SalesOrderDetails';
import AddSalesOrder from '../../Pages/SalesOrder/AddSalesOrder';
import EditSalesOrder from '../../Pages/SalesOrder/EditSalesOrder';

import CircleStatus from '../../Assets/SVG/Circle';


import './SalesOrder.css';
import EditIcon from '../../Assets/General/EditIcon';
import PdfIcon from '../../Assets/General/PdfIcon';
import EmailIcon from '../../Assets/General/EmailIcon';
import CloneIcon from '../../Assets/General/CloneIcon';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import MarkAsConfirmedIcon from '../../Assets/General/MarkAsConfirmedIcon';
import DropshipIcon from '../../Assets/General/DropshipIcon';
import BackorderIcon from '../../Assets/General/BackorderIcon';
import SaleReturnIcon from '../../Assets/General/SaleReturnIcon';
import ExportIcon from '../../Assets/General/ExportIcon';
import { checkError } from '../../Helpers/AuthHelper';
import { MdClose, BsExclamationTriangleFill } from '../../Common/Icons';

class SalesOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesOrderId: null,
      loading: true,
      data: [],
      dataExpanded: [],
      allData: [],
      store_connection: {},
      store_servers_info: {},

      totalRecords: 0,
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,

      markAsVoidModal: false,
      markAsVoidId: '',

      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',

      dropshipBackorderModal: false,
      dropshipBackorderType: '',
      dropshipBackorderData: [],

      pdf: null,
      showPDF: false,
      search: '',
      showExportModal: false,
      start: '',
      end: '',
      contactId: '',
      contactName: null,
      unSyncOrdersCount: 0,
      showPrompt: false,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'confirmed',
      label: 'Confirmed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'confirmed', page: 1 }),
    },
    {
      id: 'closed',
      label: 'Closed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'closed', page: 1 }),
    },
    {
      id: 'shipped',
      label: 'Shipped',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'shipped', page: 1 }),
    },
    {
      id: 'dropshipped',
      label: 'Dropship',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'dropshipped', page: 1 }),
    },
    {
      id: 'backorder',
      label: 'Backorder',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'backorder', page: 1 }),
    },
    /*{
      id: 'void',
      label: 'Void',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'void', page: 1 })
    },*/
    {
      id: 'invoiced',
      label: 'Invoiced',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'invoiced', page: 1 }),
    },
    {
      id: 'draft',
      label: 'Draft',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'draft', page: 1 }),
    },
    {
      id: 'market_place',
      label: 'Market Place',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'market_place', page: 1 }),
    },
    {
      id: 'woocom',
      label: 'Woo-Commerce',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'woocom', page: 1 }),
    },
    {
      id: 'shopify',
      label: 'Shopify',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'shopify', page: 1 }),
    },
    /*{
      id: 'packed',
      label: 'Packed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'packed', page: 1 })
    },
    {
      id: 'onhold',
      label: 'Onhold',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'onhold', page: 1 })
    },
    {
      id: 'fulfilled',
      label: 'Fulfilled',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'fulfilled', page: 1 })
    },*/

    {
      id: 'not fully invoiced',
      label: 'Partially Invoiced',
      type: 'link',
      onClick: () =>
        this.generateRoute({ filter: 'not fully invoiced', page: 1 }),
    },
    {
      id: 'not fully packed',
      label: 'Partially Packed',
      type: 'link',
      onClick: () =>
        this.generateRoute({ filter: 'not fully packed', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'order_date',
      label: 'Order Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('order_date') }),
    },
    {
      id: 'sales_order_no',
      label: 'Sale Order No',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('sales_order_no') }),
    },
    {
      id: 'display_name',
      label: 'Customer Name',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('display_name') }),
    },
    {
      id: 'status',
      label: 'Status',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('status') }),
    },
    {
      id: 'package_status',
      label: 'Packing Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('package_status') }),
    },
    {
      id: 'invoice_status',
      label: 'Invoice Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('invoice_status') }),
    },
    {
      id: 'total',
      label: 'Amount',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('total') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    {
      id: 'updated_at',
      label: 'Last Modified Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('updated_at') }),
    },
  ];

  importExportOptions = [
    {
      label: 'Export Sales Order',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () => !this.hasViewPermission('salesorder'),
    },
  ];
  componentDidMount() {
    document.title = 'Sales Orders';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'draft':
      case 'confirmed':
      case 'closed':
      case 'onhold':
      case 'shipped':
      case 'dropshipped':
      case 'backorder':
      case 'fulfilled':
      case 'void':
      case 'invoiced':
      case 'not fully invoiced':
      case 'not fully packed':
      case 'woocom':
      case 'shopify':
      case 'market_place':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'order_date':
      case 'sales_order_no':
      case 'display_name':
      case 'reference':
      case 'status':
      case 'invoice_status':
      case 'package_status': // case 'shipment_status':
      case 'total':
      case 'created_at':
      case 'updated_at':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  MarketFilterOptions() {
    const { wocom, shopify } = this.state.store_connection;
    let array;
    if (wocom && !shopify) {
      array = this.filterOptions.filter((option) => {
        return option.id !== 'shopify' && option.id !== 'market_place';
      });
    } else if (!wocom && shopify) {
      array = this.filterOptions.filter((option) => {
        return option.id !== 'woocom' && option.id !== 'market_place';
      });
    } else if (!wocom && !shopify) {
      array = this.filterOptions.filter((option) => {
        return (
          option.id !== 'woocom' &&
          option.id !== 'shopify' &&
          option.id !== 'market_place'
        );
      });
    } else {
      return;
    }

    this.filterOptions = array;
  }

  fetchData = async () => {
    this.setState({ loading: true });
    const currentFilter =
      this.state.currentFilter === 'all' ? '' : this.state.currentFilter;
    /*var pos = this.state.search.indexOf("&")
    //var newSearchKey = this.state.search
    if(pos !== -1){
      this.state.search = this.state.search.replace(/&/g, "%26")
    }*/
    //console.log("this.state.search--->", this.state.search);
    var url = `salesorders?page=${this.state.currentPage
      }&filter=${currentFilter}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy
      }&view=${this.state.viewPerPage}&search=${this.state.search.replace(
        /&/g,
        '%26'
      )}`;
    if (this.state.start && this.state.end) {
      url = `${url}&start=${this.state.start}&end=${this.state.end}`;
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }
    await restRequest('get', url)
      .then((result) => {
        let res = result.orders;
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'salesorders',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'salesorders') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (dataFromLS && !dataFromLS.id) {
          setObjInLS('module', {
            ...dataFromLS,
            id: res.data[0] && res.data[0].id,
            nextId: res.data[1] && res.data[1].id,
          });
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'salesorders' &&
          dataFromLS.queryParam !== window.location.search
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data?.length) {
          let obj = {
            moduleName: 'salesorders',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          sessionStorage.setItem('fullscreen', true);
        }
        if (
          dataFromLS &&
          dataFromLS.moduleName === 'salesorders' &&
          localStorage.getItem('org_switch') === 'true'
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
          localStorage.removeItem('org_switch');
        }
        this.setState(
          {
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTable(res.data),
            totalRecords: res.total,
            totalPage: res.last_page,
            loading: false,
            allData: res?.data || [],
            store_connection: result.store_connection,
            store_servers_info: result.store_servers_info,
          },
          () => {
            this.MarketFilterOptions();
            this.setState({ mainLoading: false });
          }
        );
        if (this.state.contactId) {
          this.setState({ contactName: res.data[0]?.display_name });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
    const role = getObjFromLS('role')
    role && role.type === "super admin" && restRequest('get', 'woocom/remaining_orders')
      .then((orderResponse) => {
        this.setState({ unSyncOrdersCount: orderResponse?.count });
      })
      .catch((error) => {
        checkError(error);
        this.props.handleToast(error, 'error');
      });
    this.setState({ loading: false });
  };

  prepareDataForTable(data) {
    return data.map((salesorder) => ({
      id: salesorder.id,

      Date: {
        id: 'order_date',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },
      'Sales Order': {
        id: 'sales_order_no',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },

      Customer: {
        id: 'display_name',
        sortable: true,
        market_place: salesorder?.association,
        getValue: function () {
          return salesorder[this.id];
        },
      },
      /*'Reference': {DropshipIcon,
        id: 'reference',
        sortable: true,
        getValue: function () { return salesorder[this.id] }
      },*/
      Status: {
        id: 'status',
        sortable: true,
        hideStatusColor: true,
        getValue: function () {
          const Icons = [];
          const tooltips = [];
          const showStatus = [];
          // Added for clone indication
          Icons.push(CloneIcon);
          tooltips.push('Clone order');
          if (salesorder.clone_status === 1) {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          Icons.push(DropshipIcon);
          tooltips.push('Drop shipment');
          if (
            ['dropshipped', 'partially dropshipped'].includes(
              salesorder.dropship_status
            )
          ) {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          Icons.push(BackorderIcon);
          tooltips.push('Back order');
          if (
            ['backordered', 'partially backordered'].includes(
              salesorder.backorder_status
            )
          ) {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          Icons.push(SaleReturnIcon);
          tooltips.push('Sales Return');
          if (salesorder.sales_return_status === true) {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          return (
            <HorizontalIconColumnJoin
              labelClass={getStatusColor(salesorder[this.id])}
              label={salesorder[this.id]}
              Icons={Icons}
              tooltips={tooltips}
              showStatus={showStatus}
            />
          );
        },
      },
      Packed: {
        id: 'package_status',
        sortable: true,
        getValue: function () {
          let status = salesorder[this.id];
          return (
            <CircleStatus
              status={status}
              title={status !== null ? status : 'Not Packed'}
            />
          );
        },
      },
      Invoiced: {
        id: 'invoice_status',
        sortable: true,
        getValue: function () {
          let status = salesorder[this.id];
          return (
            <CircleStatus
              status={status}
              title={status !== null ? status : 'Not Invoiced'}
            />
          );
        },
      },
      // 'Shipped': {
      //   id: 'shipment_status',
      //   sortable: true,
      //   getValue: function () {
      //     let status = salesorder[this.id]
      //     return <CircleStatus status={status} title={status !== null ? status : 'Not Shipped'} />
      //   }
      // },
      /*'Amount': {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${salesorder.currency.symbol}${formatNumber(salesorder[this.id])}`
        }
      },*/

      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          /*return `${salesorder.currency.symbol}${formatNumber(salesorder[this.id])}`*/
          return `${salesorder.currency.symbol}${parseFloat(
            salesorder[this.id]
          ).toFixed(2)}`;
        },
      },
      // not displayable
      additional: {
        status: salesorder.status,
        backorderStatus: salesorder.backorder_status,
        dropshipStatus: salesorder.dropship_status,
        invoiceStatus: salesorder.invoice_status,
        packageStatus: salesorder.package_status,
        cloneStatus: salesorder.clone_status,
        outOfStock: salesorder.itemStock.out_of_stock,
      },
    }));
  }
  prepareDataForTableCollapsed(data) {
    return data.map((salesorder) => ({
      id: salesorder.id,
      Date: {
        id: 'order_date',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },

      Status: {
        id: 'status',
        sortable: true,
        getValue: () => (
          <div className="display-flex-row salesorder_compressed_status">
            <span className={`${getStatusColor(salesorder.status)} `}>
              {salesorder.status}
            </span>
            <span>
              <CircleStatus
                status={salesorder.package_status}
                title={
                  salesorder.package_status !== null
                    ? salesorder.package_status
                    : 'Not Packed'
                }
              />
            </span>
            <span>
              <CircleStatus
                status={salesorder.invoice_status}
                title={
                  salesorder.invoice_status !== null
                    ? salesorder.invoice_status
                    : 'Not Invoiced'
                }
              />
            </span>
          </div>
        ),
        // getValue: function () { return salesorder[this.id] }
      },
      // 'Packed': {
      //   id: 'package_status',
      //   sortable: true,
      //   getValue: function () {
      //     let status = salesorder[this.id]
      //     return <CircleStatus status={status} title={status !== null ? status : 'Not Packed'} />
      //   }
      // },
      // 'Invoiced': {
      //   id: 'invoice_status',
      //   sortable: true,
      //   getValue: function () {
      //     let status = salesorder[this.id]
      //     return <CircleStatus status={status} title={status !== null ? status : 'Not Invoiced'} />
      //   }
      // },
      // not displayable
      additional: {
        status: salesorder.status,
        backorderStatus: salesorder.backorder_status,
        dropshipStatus: salesorder.dropship_status,
        invoiceStatus: salesorder.invoice_status,
        packageStatus: salesorder.package_status,
        cloneStatus: salesorder.clone_status,
        outOfStock: salesorder.itemStock.out_of_stock,
      },
    }));
  }

  close = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `salesorders/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  }

  print(id) {
    this.setState({ loading: true });
    restRequest('get', `salesorders/${id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 100);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error,'error')
        this.setState({ loading: false });
      });
  }

  markAsConfirmed = (id) => {
    this.setState({ processing: true });
    restRequest('put', `salesorders/${id}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        this.props.handleToast(
          'Sales order has been marked as confirmed.',
          'success'
        );
        this.updateCheckedState();
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  bulkMarkAsConfirmed(ids) {
    this.setState({
      processing: true,
    });
    restRequest('put', 'salesorders/confirmed', {
      ids: ids,
    })
      .then((res) => {
        this.setState({ processing: false });
        this.props.handleToast(res.message, 'success');
        this.updateCheckedState();
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  }

  // check status whether its active or not
  checkStatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.status === status
    );
  };
  checkStock = (id) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return this.state.data.find((one) => {
      return one.id === currentId;
    }).additional.outOfStock;
  };

  checkDropshipstatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.dropshipStatus === status
    );
  };

  checkPackedstatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.packageStatus === status
    );
  };

  checkBackOrder = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.backorderStatus === status
    );
  };

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  markAsVoid = () => {
    restRequest('put', `salesorders/${this.state.markAsVoidId}/void`)
      .then(() => {
        this.props.handleToast(
          'Sales Order has been marked as void.',
          'success'
        );
        this.fetchData();
        this.updateCheckedState();
        this.closeMarkAsVoidModal();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        this.closeMarkAsVoidModal();
      });
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      markAsVoidModal: true,
      markAsVoidId: id,
    });
  };

  closeMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: false,
    });
  };

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeMarkAsVoidModal}
        onSubmit={() => this.markAsVoid()}
      >
        Are you sure you want to mark this sales order as Void?
      </CustomModal>
    );
  }

  hidePDFModal = () => {
    this.setState({
      showPDF: false,
    });
  };

  renderBulkDropshipBackorderModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={this.state.dropshipBackorderModal}
        title={`${this.state.dropshipBackorderType === 'dropship'
          ? 'Dropship'
          : 'Backorder'
          }`}
        renderActions={false}
        Icon={
          this.state.dropshipBackorderType === 'dropship'
            ? DropshipIcon
            : BackorderIcon
        }
        modaltitle={'align_so_modalicons'}
        onClose={this.closeDropshipBackorderModal}
        showPrompt={this.state.showPrompt}
      >
        <BulkDropshipBackorder
          handleToast={this.props.handleToast}
          closeModal={this.closeDropshipBackorderModal}
          type={this.state.dropshipBackorderType}
          ids={this.state.dropshipBackorderData}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  };

  closeDropshipBackorderModal = () => {
    this.setState({
      dropshipBackorderModal: false,
    });
  };

  toggleBulkDropshipBackorderModal = (type, id = false) => {
    this.setState({ processing: true });
    const data = id ? [id] : this.state.bulkSelectedData;
    const requestType = 'get';
    const requestUrl = `salesorders/${type}?ids=${data}`;
    shouldShowModal(requestType, requestUrl)
      .then(() => {
        this.setState({ processing: false });
        let dataFromLS = getObjFromLS('module');
        let { pathname, search } = window.location;
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'salesorders',
          back_drop: { id: id, url: `${pathname}${search}` },
          queryParam: dataFromLS.queryParam,
        });
        // setObjInLS('module',{...dataFromLS , back_drop:{id:id, url:`${pathname}${search}`}})
        this.setState({
          dropshipBackorderType: type,
          dropshipBackorderData: data,
          dropshipBackorderModal: true,
        });
      })
      .catch((error) => {
        this.setState({ processing: false });
        this.props.handleToast(error, 'error');
      });
  };

  populateConditionalOptions(id, isVoid, isConfirmed) {
    const hasEditPermission = this.hasPermission('salesorder', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;
    if (!isConfirmed) {
      options.push({
        label: 'Mark as Confirmed',
        onClick: () => this.markAsConfirmed(id),
      });
    }
    if (!isVoid) {
      options.push({
        label: 'Mark as Void',
        onClick: () => this.openMarkAsVoidModal(id),
      });
    }

    if (!isVoid && isConfirmed) {
      options.push({
        label: 'Dropship',
        onClick: () => this.toggleBulkDropshipBackorderModal('dropship'),
      });
      options.push({
        label: 'Backorder',
        onClick: () => this.toggleBulkDropshipBackorderModal('backorder'),
      });
    }
    return options;
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const isConfirmed = this.checkStatus('confirmed');
    const isVoid = this.checkStatus('void');
    const checkedId = this.state.bulkSelectedData[0];
    const options = this.populateConditionalOptions(
      checkedId,
      isVoid,
      isConfirmed
    );
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('salesorder', 'All');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={!isVoid && hasEditPermission}
          to={`/salesorders/edit/${checkedId}`}
          type="edit"
        />
        <Button handleClick={() => this.openPdf(checkedId)} type="pdf" />

        <Button handleClick={() => this.print(checkedId)} type="print" />
        <ButtonLink
          enable={hasCreatePermission}
          to={`/salesorders/email/${checkedId}`}
          type="email"
        />
        <ButtonLink
          enable={hasCreatePermission}
          to={`/salesorders/clone/${checkedId}`}
          type="clone"
        />
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />
        <Button handleClick={() => this.exportCurrentView()} type="export" />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  populateConditionalOptionsBulkSelect() {
    const hasEditPermission = this.hasPermission('salesorder', 'Edit');
    const options = [];
    // if(!hasEditPermission) return options
    options.push({
      label: 'Mark as Confirmed',
      onClick: () => this.bulkMarkAsConfirmed(this.state.bulkSelectedData),
      disabled: () => !hasEditPermission,
    });
    options.push({
      label: 'Export Selected',
      onClick: () => this.exportCurrentView(),
      disabled: () => !this.hasViewPermission('salesorder'),
    });

    return options;
  }

  exportCurrentView = () => {
    restRequest('post', `salesorders/exportCurrentView`, {
      sales_order_ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `SalesOrders.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`Sale Orders exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };
  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const [, , hasDeletePermission] = this.hasPermission('salesorder', 'All');
    const options = this.populateConditionalOptionsBulkSelect();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  isOrderEditable(id) {
    let currentOrder = this.state.data.find(
      (salesOrder) => salesOrder.id === id
    );
    return (
      currentOrder.additional.backorderStatus !== null ||
      currentOrder.additional.dropshipStatus !== null ||
      currentOrder.additional.invoiceStatus !== null ||
      currentOrder.additional.packageStatus !== null
    );
  }
  isOrderCreateable() {
    const [hasCreatePermission] = this.hasPermission('salesorder', 'All'); //, hasEditPermission, hasDeletePermission
    return !hasCreatePermission;
  }

  isSyncOrderExist = (id) =>
    this.state.allData?.some((order) => order.id === id && order.sync_order);

  prepareRowOptions() {
    const [, , hasDeletePermission] = this.hasPermission('salesorder', 'All');
    const rowOptions = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`salesorders/edit/${id}`),
        disabled: (id) =>
          this.isOrderEditable(id) ||
          this.checkStatus('void', id) ||
          this.isSyncOrderExist(id),
      },
      {
        label: 'Clone',
        type: 'link',
        icon: CloneIcon,
        onClick: (id) => `/salesorders/clone/${id}`,
      },
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: (id) => this.openPdf(id),
      },
      {
        label: 'Email',
        // type: 'link',
        icon: EmailIcon,
        onClick: (id) => this.props.history.push(`/salesorders/email/${id}`),
        disabled: () => this.isOrderCreateable(),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: (id) => this.openDeleteModal(id),
        disabled: (id) =>
          (hasDeletePermission &&
            !this.checkStatus('void', id) &&
            this.isOrderEditable(id)) ||
          this.isSyncOrderExist(id),
      },
      {
        label: 'Mark as Confirmed',
        onClick: (id) => this.markAsConfirmed(id),
        icon: MarkAsConfirmedIcon,
        disabled: (id) =>
          this.checkStatus('confirmed', id) ||
          this.checkStatus('closed', id) ||
          this.checkBackOrder('backordered', id),
      },
      /*{
        label: 'Mark as Void',
        onClick: id => this.openMarkAsVoidModal(id),
        icon: MarkAsVoidIcon,
        disabled: id => this.checkStatus('void', id) || this.checkStatus('closed', id) || this.checkStatus('draft', id) || this.isOrderEditable(id)
      },*/
      {
        label: 'Dropship',
        icon: DropshipIcon,
        onClick: (id) => this.toggleBulkDropshipBackorderModal('dropship', id),
        disabled: (id) =>
          this.checkStatus('void', id) ||
          this.checkStatus('closed', id) ||
          !this.checkStatus('confirmed', id) ||
          this.checkDropshipstatus('dropshipped', id) ||
          this.checkPackedstatus('packed', id) ||
          this.checkBackOrder('backordered', id),
      },
      {
        label: 'Backorder',
        icon: BackorderIcon,
        onClick: (id) => this.toggleBulkDropshipBackorderModal('backorder', id),
        disabled: (id) =>
          this.checkStatus('void', id) ||
          this.checkStatus('closed', id) ||
          !this.checkStatus('confirmed', id) ||
          !this.checkStock(id) ||
          this.checkDropshipstatus('dropshipped', id) ||
          this.checkBackOrder('backordered', id),
      },
    ];

    const permissions = this.hasPermission('salesorder', 'All');

    const allowedRowOptions = GenerateOptions(permissions, rowOptions, [
      'Mark as Confirmed',
      'Mark as Void',
      'Dropship',
      'Backorder',
    ]);

    return allowedRowOptions;
  }

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'salesorders',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ salesOrderId: id });
    // setValueInLS('id',id)
  }
  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Sales Orders"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="SalesOrders"
          onCancel={this.closeExportModal}
          submitURL="salesorders/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  unSynOrders = () => {
    return (
      <>
        <div style={{ clear: 'both' }}>
          <Link to="/un-sync-orders">
            <div className="unsync-alert">
              <div className="unsync-desc">
                <p>Total unsync orders are:</p>
                <a href="/#">click here</a>
              </div>
              <span className="sync-count">{this.state.unSyncOrdersCount}</span>
            </div>
          </Link>
        </div>
      </>
    );
  };

  warningMessage = () => {
    const { color, data } = this.state.store_servers_info
    const { disabled_stores, under_retrying } = data

    return (
      <>
        <div style={{ clear: 'both' }}>
          <div className="warning-alert" style={{ backgroundColor: color == "red" ? "#f8d7da" : "#fff3cd" }}>
            <div className="unsync-desc" >
              {disabled_stores && <p> <BsExclamationTriangleFill size={18} /> Disabled Stores: {disabled_stores} </p>}
              {under_retrying && <p><BsExclamationTriangleFill size={18} /> Under Retrying Stores: {under_retrying}  </p>}
            </div>
            <div className='closebtn'>
              <button onClick={() => { this.setState({ store_servers_info: { ...this.state.store_servers_info, status: false } }) }}><MdClose size={18} /></button>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderTable() {
    const {
      data,
      bulkSelectedData,
      dataExpanded,
      sortFilter,
      orderBy,
      //loading,
      mainLoading,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        return (
          // <Onboarding
          //   name="Sales Order"
          //   Image={SalesOrderImage}
          //   FlowDiagram={SalesOrderFlowDiagram}
          //   title="Manage Sales Activity"
          //   description="Create, customize and manage your sales orders"
          //   buttonLabel="Create Sales Order"
          //   buttonLink="/salesorders/add"
          // />
          <Onboarding
            name="Sales Order"
            FlowDiagram={SalesOrderFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/-wl3Rt2UCTM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewSalesOrderIcon}
            headerTitle="Sales Order"
            title="Manage Sales Activity"
            description="Create, customize and manage your sales orders"
            buttonLabel="Create Sales Order"
            buttonLink="/salesorders/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    if (fullscreenFlag === 'true') {
      sessionStorage.setItem('fullscreen', false);
      sessionStorage.setItem('fullscreenFlag', false);
    }

    return (
      <>
        <div className="sales_order_table_listing">
          {this.state.unSyncOrdersCount !== 0 && this.unSynOrders()}
          {this.state.store_servers_info.status && this.warningMessage()}
          <ListTable
            data={fullscreen === 'true' ? dataExpanded : data}
            selectedData={bulkSelectedData}
            rowOptions={this.prepareRowOptions()}
            className={
              fullscreen === 'true'
                ? 'fullscreen so_main_list global-status-listing'
                : 'compressedscreen salesorder_listing sales_order_compressed'
            }
            linkTo="/salesorders"
            preferenceLink="/preference/salesorder"
            preference="Sales Order Preferences"
            sortFilter={sortFilter}
            orderBy={orderBy}
            loading={mainLoading}
            generateRoute={this.generateRoute}
            updateCheckedState={this.updateCheckedState}
            handleCheckAll={this.handleCheckAll}
            handleCheckSingle={this.handleCheckSingle}
            getID={this.getID}
            moduleName="salesorders"
            totalRecords={this.state.totalRecords}
          />
        </div>
      </>
    );
  }

  onClickHandler = () => {
    this.setState({ contactId: '', contactName: '' });
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'salesorders') {
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.salesOrders);
    }
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.salesOrders}
        >
          <SearchResult
            criterias={['Sales Order No', 'Customer Name', 'Reference']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Sales Orders"
          linkTo={routesPath.salesOrders}
          crossIcon={false}
        //onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        {/* {this.renderSearchDetails()} */}
        {this.renderExportModal()}
        {this.renderBulkDropshipBackorderModal()}
        {this.renderMarkAsVoidModal()}
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.close}
          pdf={this.state.pdf}
        />
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(
  SalesOrder,
  {
    name: 'Sales Orders',
    Icon: SalesOrderIcon,
    baseUrl: 'salesorders',
    tableIcon: 'general_module_icon',
    deleteName: 'Sales Order',
    redirectUrl: '/salesorders',
    buttonUrl: '/salesorders/add',
    buttonLabel: 'New Sales Order',
    routeName: 'salesorders',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'salesorder',
  },
  SalesOrderDetails,
  AddSalesOrder,
  EditSalesOrder
);